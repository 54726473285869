@import url(./index.css);
@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);

.bg-center {
  background-position: center !important;
}

* {
  font-family: "Poppins", sans-serif;
}
*:focus {
  outline: none !important;
}
.poppins {
  font-family: "Poppins", sans-serif;
}
.material-header-btn.MuiButtonBase-root {
  margin-left: -20%;
}
.bg-cover {
  background-size: cover !important;
}
@keyframes moveDownAnimation {
  100% {
    background-position-y: 100%;
  }
}

.lines-animation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../assets/lines.jpg);
  background-size: 7px auto;
  mix-blend-mode: overlay;
  pointer-events: none;
  opacity: 0.4;
  animation: moveDownAnimation 15s linear infinite;
}
.vignette {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../assets/vignette.png);
  z-index: 1;
  background-size: 100% 100%;
  mix-blend-mode: overlay;
  pointer-events: none;
  opacity: 0.6;
}
.sounds {
  display: none;
  color: "white";
  position: sticky;
}
/* Play Btn */
.withborder {
  position: absolute;
  bottom: -250px;
  padding: 10px;
  width: 500px;
  background-color: #111;
  border-radius: 10px;
  border: 2px solid #674db4;
}
.noborder {
  padding-top: 3px;
  width: 555px;
  border: 0px solid #674db4;
}
.control-btn {
  width: 10px;
  height: 35px;
  border-color: transparent transparent transparent #fff;
  transition: 100ms all ease;
  cursor: pointer;
  border-style: solid;
  border-width: 17px 0 17px 30px;
  transform: scale(0.4);
}
.control-btn.paused {
  border-style: double;
  border-width: 0px 0px 0px 30px;
}
.MuiButton-label {
  color: #fff !important;
}
.MuiSlider-root {
  color: #674db4 !important;
}

/* Spectrum Animation */
.line {
  width: 0.2em;
  margin-left: 3px;
}
.spectrum .line1 {
  animation: animateLine1 1.3s infinite;
}
.spectrum .line2 {
  animation: animateLine2 1.3s infinite;
}
.spectrum .line3 {
  animation: animateLine3 1.3s infinite;
}
.spectrum .line4 {
  animation: animateLine4 1.3s infinite;
}

@keyframes animateLine1 {
  0% {
    height: 0.9em;
  }
  25% {
    height: 1.2em;
  }
  50% {
    height: 0.4em;
  }
  75% {
    height: 1.3em;
  }
  100% {
    height: 1.3em;
  }
}
@keyframes animateLine2 {
  0% {
    height: 1em;
  }
  25% {
    height: 0.4em;
  }
  50% {
    height: 0.8em;
  }
  75% {
    height: 1em;
  }
  100% {
    height: 0.6em;
  }
}
@keyframes animateLine3 {
  0% {
    height: 0.2em;
  }
  25% {
    height: 1.3em;
  }
  50% {
    height: 0.7em;
  }
  75% {
    height: 1em;
  }
  100% {
    height: 0.9em;
  }
}
@keyframes animateLine4 {
  0% {
    height: 0.1em;
  }
  25% {
    height: 1.2em;
  }
  50% {
    height: 0.4em;
  }
  75% {
    height: 0.3em;
  }
  100% {
    height: 1em;
  }
}

/* Track Btn */
.track {
  display: block !important;
  text-transform: none !important;
  height: 100% !important;
  text-align: left !important;
}

.MuiFormControl-root * {
  font-family: "Acme", sans-serif !important;
}
.twitterBtn {
  padding: 7px 9px !important;
}

.custom-cursor {
  cursor: url("../assets/cursor.png"), auto;
}
.cursor-pointer {
  cursor: pointer !important;
}

/* Scroll bar */
::-webkit-scrollbar {
  width: 17.5px;
  position: relative !important;
  background: #111;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

kbd {
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #b4b4b4;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2),
    0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
  color: #333;
  display: inline-block;
  font-size: 0.85em;
  font-weight: 700;
  line-height: 1;
  padding: 2px 4px;
  white-space: nowrap;
}

@media (min-width: 600) {
  .hiddenOnMobileBtns {
    display: none !important;
  }
}
.swiper-container {
  width: calc(100% - 90px);
  transform:translate3d(0,0,0);
  overflow:hidden;
  height: 100%;
  display: flex;
  margin: 0;
}

.anime-swiper{
  transform:translate3d(0,0,0);
  overflow:hidden;
}

/* Instruction for pomodoro */
.instruction-wrap {
  position: relative;
  margin-top: 30px;
  font-size: 35px;
}

.instruction-wrap .instruction-content {
  display: none;
}
.instruction-wrap:hover .instruction-content {
  display: block;
  font-size: 0.5em;
}

.work-time-tag {
  font-size: 1em;
}

/* Mood mix */
.sounds-icon {
  color: #674db4;
}

.mood-image {
  transition: 0.3s;
}
.mood-image:hover,
img:hover {
  transform: scale(1.05);
}
.mood-image > img:hover {
  opacity: 1;
}
/* Links */
.links {
  color: #674db4;
  transition: transform 0.2s;
}
/* Faq Button CSS */
.faq_button {
  position: -webkit-sticky;
  background-color: #674db4;
  height: 55px;
  width: 55px;
  border-radius: 50%;
  font-family: ui-monospace, SFMono-Regular, Consolas, "Liberation Mono", Menlo,
    monospace;
  font-size: 1.5rem;
  top: 85%;
  left: 90%;
  padding-left: 7px;
  padding-bottom: 2px;
  transition: 0.4s;
}
.faq_button:hover {
  background-color: lavenderblush;
  transform: translateY(-8px);
  color: #674db4;
}
.modal {
  position: absolute;
  right: 0;
  top: 62%;
  right: 8%;
  padding: 10px;
  margin: 16px;
  background: linear-gradient(#765fbc, #674db4);
  height: 215px;
  width: 200px;
  border-radius: 5px;
  z-index: 1;
  display: none;
}
.modal-show {
  display: block;
}
.modal-hide {
  display: none;
}
/* Player Button CSS */
.player_button {
  position: -webkit-sticky;
  height: 55px;
  width: 55px;
  padding-right: 5.5px;
  border-radius: 50%;
  transition: 0.4s;
  left: 7%;
  top: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  z-index: 10;
}
.player_button:hover {
  background-color: lavenderblush;
  transform: translateY(-8px);
  color: #674db4;
}
.player-modal {
  top: 69%;
  left: 8%;
  padding: 2px;
  padding-top: 20px;
  margin-top: 20px;
  height: 125px;
  width: 600px;
  border-radius: 15px;
  border: 2px solid #674db4;
}
.player-show {
  background-color: #111;
  display: block;
}
.player-hide {
  display: none;
}
#close_player {
  align-self: flex-start;
  font-size: 20px;
}
/* Stop Icon */
.stop_icon {
  font-size: 26px;
  margin-right: 2px;
}

/* Anime Rec */
.animerec {
  transition: 0.3s;
}

.animerec:hover {
  transform: scale(1.05);
}

/* Kits */
.downloadbtn {
  font-size: 25px;
}

.download-footer {
  display: flex;
  justify-content: center;
  gap: 5px;
  border-radius: 5px;
  border: 2px solid #674db4;
  transition: 0.3s;
  width: 200px;
  height: 50px;
}

.download-footer:hover {
  transform: scale(1.05);
}

.header {
  padding-top: 13px;
}

.bigcontainer {
  overflow: hidden;
}

.screen {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.screen ::-webkit-scrollbar {
  display: none;
}

/* Mobile responsiveness */

#content-desktop {
  display: block;
}
#content-mobile {
  display: none;
}
#desktop-header {
  display: block;
}

/* @media screen and (max-width: 768px) {
  #content-desktop {
    display: none;
  }
  #content-mobile {
    display: block;
  }
  #desktop-header {
    display: none;
  }
} */

/* Anime Therapy */
.anime_therapy {
  border: solid #674db4;
  width: 340px;
  height: 580px;
  padding: 20px;
  transition: 0.5s;
}
.anime_therapy > a > img {
  width: 200px;
  height: 200px;
  border-radius: 5px;
  margin: 5px;
}
.anime_therapy:hover {
  transform: translateY(10px);
}
.anime_therapy-logo {
  display: inline;
}

/* @media screen and (max-height: 850px) {
  .anime_therapy{
    padding: 10px;
    width: 280px;
    height: 480px;
  }
  .anime_therapy > a > img {
  width: 150px;
  height: 150px;
  border-radius: 5px;
  margin: 5px;
  }
} */

.all_slide {
  height: 200px;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  position: relative;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
}

.cont {
  width: 80%;
  margin: 0 auto;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

/* Override navigation CSS */
.swiper-button-prev {
  left: 10px;
}
.swiper-button-next {
  right: 10px;
}

@media (min-width: 768px) and (max-width: 980px) {
  .swiper-button-prev {
    left: 0px;
  }
  .swiper-button-next {
    right: 0px;
  }
}




